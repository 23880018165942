.map-wrapper 
	@apply h-full mt-10
	a
		display: block
		height: 830px
		@apply overflow-hidden
		text-align: center
		width: 100%
		@media screen and ( max-width: 1200px)
			height: 500px
		@media screen and ( max-width: 1024px)
			height: 400px
		@media screen and ( max-width: 576px)
			height: 300px
		iframe
			@apply inline-block w-full h-full
.contact-us
	.wrap-form
		.form-group
			textarea
				height: 90px
				@apply pl-0
	.bg-wrap
		&::after
			content: ''
			@apply block absolute pointer-events-none right-0 bottom-0 z-10  transition 
			background: url('../img/overlay/overlay.png')
			background-repeat: no-repeat
			background-size: contain
			background-position: right bottom-0
			width: r(140px)
			height: r(140px)
			@media screen and ( max-width: 1200px)
				width: r(90px)
				height: r(90px)
			@media screen and ( max-width: 576px)
				width: r(60px)
				height: r(60px)
.filter-address-select
	@apply relative z-40
	.custom-select-2
		@apply border-b border-b-secondary-400 bg-transparent py-4 relative
		&::after
			content: ''
			@apply block absolute-y pointer-events-none right-[20px] z-10  transition leading-none font-bold text-primary-900
			background: url('../img/icon/caret-down.png')
			background-repeat: no-repeat
			background-size: contain
			background-position: center
			height: 24px
			width: 24px
			@media screen and ( max-width: 1024px)
				height: 20px
				width: 20px
		select 
			@apply bg-transparent text-clamp-24 font-bold text-white pl-0 w-full pr-8
			-webkit-appearance: none
			-moz-appearance: none
			option
				@apply text-clamp-20 font-bold text-grey-900 

	.item
		@apply hidden not-italic
		address
			@apply not-italic
		li
			@apply start-item mb-3 text-clamp-16
			align-items: flex-start
			*
				@apply text-clamp-16
			em
				@apply mr-3 font-black text-clamp-20
		&.active
			@apply block
	p
		@apply mb-0
		span
			@apply block