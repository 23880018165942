.news-detail 
	@apply pt-6
	.container
		@apply border-b border-b-grey-200
	.section-title
		@apply normal-case
.news-detail-page
	.global-breadcrumb
		@apply hidden
		&:first-child
			@apply block
.news-detail-category
	.news-category
		@apply block
.news-category
	@apply mt-8 hidden
	a
		&:hover
			@apply border-white
.news-side
	@apply start-item
	align-items: center
	padding: r(10px) 0 
	@media screen and ( max-width: 576px)
		@apply col-hor
	&:first-child
		@apply pt-0
	&:hover
		.headline
			a
				@apply underline
	time
		font-size: 14px
		@apply text-grey-500 mb-1

	.headline
		a
			@apply text-clamp-16
			+line(3)
	.img
		@media screen and ( max-width: 576px)
			@apply w-full mb-4
		a
			@apply overflow-hidden rounded-2
			@media screen and ( max-width: 576px)
				+img-ratio(9/16)
				@apply w-full
			@screen sm
				@apply center-item mr-4
				height: 110px
				width: r(170px)
				min-width: r(170px)
				max-width: r(170px)
				img
					width: 100%
					height: 100%
					object-fit: cover
