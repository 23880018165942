.program-summary-2
	.img
		a
			@apply center-item
.program-summary-3
	.zone-desc
		@apply mb-15
		@screen xl
			@apply mb-20
	.row
		@apply m-0 p-0 mx-auto justify-center
		.col
			@apply m-0 p-0 
			&:nth-child(even)
				.item
					&::after
						bottom: unset
						top: 0
		@media screen and ( max-width: 1024px)
			&:nth-child(1),&:nth-child(2)
				.item
					margin-bottom: 70px
	.item
		padding: 10px
		@apply h-full
		&::after
			content: ''
			@apply block absolute pointer-events-none left-0 bottom-0 z-10 rounded-4 transition w-full h-1/2 overflow-hidden
			background: var(--color-code)
	
		@media screen and ( max-width: 1024px)
			&:nth-child(1),&:nth-child(2)
				margin-bottom: 70px
				
	.cirlce
		width: 100px
		height: 100px
		background: var(--color-code)
		padding: 10px
		@apply absolute-x  z-10
		top: -50px
	.number
		@apply bg-white h-full w-full
		box-shadow: 0px 3px 8px 0px #00000033
		span
			color: var(--color-code)
	.title
		color: var(--color-code)
	.txt
		@apply justify-between
	.wrap
		box-shadow: 0px 5px 16px 0px #00000099
		@apply p-5 pt-20 pb-8
		@screen xl
			padding: r(100px) r(40px) r(60px) r(40px)