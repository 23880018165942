.home-about
	@media screen and ( max-width: 1024px)
		@apply pb-15
	.block-wrap
		@screen lg
			padding: 0 8% r(40px) r(25px)
		@screen xl
			padding-bottom: r(60px)
	.block-title
		@screen xl
			@apply text-clamp-64
	.img
		@apply flex items-end
		a
			@apply flex items-end
			@media screen and ( max-width: 1024px)
				@apply center-item
	.zone-desc
		strong
			@apply text-primary-900
			*
				@apply text-primary-900
	hr
		@apply block my-5 bg-primary-900
		@screen lg
			@apply my-6
		@screen xl
			@apply  my-8
	.number
		@media screen and ( max-width: 576px)
			@apply w-1/2 text-center
			&:last-child
				@apply w-full pt-5
	.counter
		@apply pb-3 text-clamp-64 font-bold text-primary-00B9AB leading-none
		*
			@apply text-clamp-64 font-bold text-primary-00B9AB leading-none
		@screen lg
			@apply pb-4
	.item
		@media screen and ( max-width: 768px)
			&:not(:last-child)
				@apply mb-8
		.bg
			background: var(--color-code)
			@apply h-full
		&::after
			content: ''
			@apply block absolute pointer-events-none left-[-16px] top-[-20px] z-10  transition w-[32px] h-[40px]
			background: url('../img/icon/medal.png')
			background-repeat: no-repeat
			background-size: cover
			background-position: center
			@media screen and ( max-width: 576px)
				@apply left-[-10px] top-[-15px]    w-[20px] h-[30px]
