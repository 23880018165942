.primary-banner
	@apply relative overflow-hidden
	.banner-container
		@apply relative
		@media screen and ( max-width: 1200px)
			position: relative
			display: block
			height: 0
			overflow: hidden 
			padding-top: 960/1920 *100%
			.swiper
				position: absolute
				width: 100%
				height: 100%
				top: 0
				left: 0
	.wrap
		@apply relative
	.container
		@apply absolute-center text-left z-30
	.bg-overlay
		@apply relative
		&::after
			content: ''
			@apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-full h-full
			background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 9.90%, rgba(0, 0, 0, 0.10) 56.25%, rgba(0, 0, 0, 0.50) 93.75%)
	.img
		@apply relative
		a
			+img-ratio(960/1920)
	.swiper-slide
		&.swiper-slide-active
			.title
				@apply opacity-0
				animation: fade-in 1s cubic-bezier(0.39, 0.575, 0.565, 1) .7s both
	.txt
		@apply text-center
		@screen lg
			@apply w-1/2 mr-auto text-left
	.block-title
		@screen xl
			@apply  text-clamp-64
			*
				@apply  text-clamp-64

		@media screen and ( max-width: 576px)
			@apply text-clamp-24
			*
				@apply text-clamp-24
	.swiper-pagination
		width: 100% !important
		bottom: r(50px) !important
		@apply center-item
		@media screen and ( max-width: 576px)
			bottom: r(20px) !important
	.swiper-pagination-bullet
		@apply  rounded-full transition opacity-100 center-item bg-transparent relative  center-item overflow-hidden
		border: 2px solid rgba(#fff,.5 )
		height: 12px
		width: 12px
		&.swiper-pagination-bullet-active
			@apply opacity-100 border-white bg-white
			&::after
				content: ''
				@apply bg-white