.about-1
	.item
		&:not(:last-child)
			@apply mb-10
	.img
		@apply flex items-end h-full
	.txt-wrap
		@media screen and ( max-width: 1024px)
			@apply order-2
	.img-wrap
		@media screen and ( max-width: 1024px)
			@apply center-item order-1 p-5
	.block-title
		@apply w-fit
.about-2
	.container
		background: url('../img/about/global.png')
		background-repeat: no-repeat
		background-size: contain
		background-position: center
	.block-title
		@apply normal-case
		@screen lg
			max-width: 220px
	.logo
		.img
			@apply pl-5
			a
				@apply start-item
				height: r(150px)
				width: auto
				img
					width: auto
					height: 100%
					object-fit: contain
	.counter
		@apply w-[80px]
	.txt 
		@apply text-primary-900 text-clamp-24 py-4
		*
			@apply text-primary-900 text-clamp-24
		p
			@apply mb-0
		span,strong
			@apply block
	.img-wrap
		@media screen and ( max-width: 1024px)
			@apply pt-8
		a
			@apply end-item
.about-3
	.headline
		
	.item
		background: var(--color-code)
		@apply flex flex-col justify-between h-full
	.img
		@apply h-full
		a
			@apply center-item
			height: 100%
			width: 100%
			img
				width: 100%
				height: 100%
				object-fit: cover
	.txt
		min-height: r(160px)
		height: inherit
		@apply justify-center 
	.swiper-slide
		@apply mt-0 !#{important}
	.swiper
		@apply rounded-4 overflow-hidden
.about-4
	.year
		color: var(--color-code)
		height: r(220px)
	.item
		@apply col-ver
	.dot
		@apply absolute left-0 bottom-0 z-10  w-0 pointer-events-none
		height: r(310px)
		&::before,&::after
			content: ''
			@apply block absolute pointer-events-none z-10  transition
		&::before
			@apply w-[1px]  top-0
			height: r(310px)
			background: var(--color-code)
		&::after
			@apply w-[13px] h-[13px] rounded-full overflow-hidden top-0
			background: var(--color-code)
			left: -6px
	.txt
		border-color: var(--color-code)
		height: r(220px)
		&::after
			content: ''
			@apply block absolute pointer-events-none left-0 top-0 z-10  transition w-full h-[4px] 
			background: var(--color-code)
		.desc
			@apply mt-auto
			max-height: r(180px)
			height: auto
			padding-right: 5px
			overflow-y: auto
			scrollbar-width: thin
			scrollbar-color: #F15F4E #DCDCDC
			&::-webkit-scrollbar
				background: #DCDCDC
				width: 3px
				border-radius: 5px
			&::-webkit-scrollbar-track
				-webkit-box-shadow: inset 0 0 6px #DCDCDC
				background-color: #DCDCDC
				border-radius: 5px
			&::-webkit-scrollbar-thumb
				background-color: #F15F4E
				border-radius: 5px
	.swiper-history
		@screen lg
			@apply flex items-center
	.swiper
		@apply -mx-3 px-3 w-full
	.swiper-slide
		&:nth-child(odd)
			.txt
				&::after
					box-shadow: 0px 4px 4px 0px #00000040
			.year
				@apply flex items-end
		&:nth-child(even)
			.dot
				top: 0
				bottom: unset !important
				&::after
					@apply top-full
			.item
				@apply flex-col-reverse
			.txt
				@apply flex items-end
				&::after
					@apply bottom-[-4px]
					top: unset
					box-shadow: 0px -4px 4px 0px #00000040
.about-5
	.first-row,.second-row,.third-row
		@apply flex justify-center flex-wrap -mx-2 mb-0
		.col
			@apply px-2
.person-figure
	@apply h-full
	background: var(--color-code)
	.txt
		@apply text-center
	.img
		a
			+img-ratio(312,320)