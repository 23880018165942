.recruit-2
.list-bold-wrap
	@apply pt-4
	@screen xl
		@apply pt-6
	ul,ol
		list-style-type: disc
		padding-left: 0
		@apply flex flex-wrap -mx-4
		li
			@apply flex items-start text-clamp-24 font-bold text-primary-900 py-2 px-4 text-left
			@screen xl
				@apply py-3
			*
				@apply text-clamp-24 font-bold text-primary-900
			@screen sm
				@apply w-1/2 
			&::before
				content: ''
				@apply block mt-2 pointer-events-none z-10  transition w-[12px] h-[12px]  rounded-full mr-3 min-w-[12px] bg-secondary-FAAF3C
.recruit-list
	.wrap-center
		.btn
			width: 200px
.mobile-filter
	@media screen and ( max-width: 1024px)
		@apply fixed top-[60px] left-0  w-full h-full bg-white p-8 pt-6 opacity-0 pointer-events-none transition
		z-index: 90
	&.is-open
		@apply opacity-100 pointer-events-auto
	.close-filter
		@apply hidden
		@media screen and ( max-width: 1024px)
			@apply center-item h-[40px] bg-red overflow-hidden rounded font-medium  absolute-x bottom-[80px] 
			width: 96%
		span
			@apply text-white  text-clamp-18
.filter-toggle
	@apply fixed bg-primary-900 overflow-hidden z-40 rounded center-item top-1/2 left-[5px] w-[36px] h-[36px]
	@screen lg
		@apply hidden 
	em
		@apply text-white text-clamp-20
.filter-wrap
	@media screen and ( max-width: 1024px)
		@apply order-2 pt-2
	@screen lg
		@apply start-item
.filter-item
	&:not(:last-child)
		@screen lg
			@apply mr-5
	@media screen and ( max-width: 1024px)
		@apply py-3
	.filter-select
		@apply relative
		&::after
			content: '\f0d7'
			@apply block absolute-y pointer-events-none right-0 z-10  transition leading-none font-bold text-grey-400 font-Awesome6
			background-repeat: no-repeat
			background-size: contain
			background-position: center
			height: 18px
			width: 14px
			@media screen and ( max-width: 1024px)
				@apply right-[15px]

		select 
			@apply bg-transparent text-clamp-24  text-grey-500 pl-0 w-full pr-8 transition
			-webkit-appearance: none
			-moz-appearance: none
			option
				@apply text-clamp-24  text-grey-500
		@media screen and ( max-width: 1024px)
			@apply shadow-lg p-2  rounded-2
			select
				@apply h-[36px]  text-clamp-20 pl-3
				
		&:hover
			select
				@apply text-secondary-400
			&::after
				@apply text-secondary-400
.filter-search
	@media screen and ( max-width: 1024px)
		@apply w-full order-1
	.wrap-form
		.form-group
			@apply end-item 
			@media screen and ( max-width: 1024px)
				@apply shadow-lg p-2  rounded-2
			@screen lg
				@apply border-b border-b-grey-200 
			input
				@apply h-[40px] border-b-transparent pr-8 w-full pb-2
				&:focus
					@apply outline-none border-b-transparent
				@media screen and ( max-width: 1024px)
					@apply pb-0 pl-5
	button
		@media screen and ( max-width: 1200px)
			@apply bg-grey-800 w-[36px] h-[36px]  rounded-[4px] pr-0 mr-2
			min-width: 36px
			em
				@apply text-white text-clamp-24
.recruit-item
	&:not(:last-child)
		@apply mb-5
	&:hover
		.sub-title
			a
				@apply text-secondary-400
	.info
		@apply start-item
		@media screen and ( max-width: 768px)
			@apply justify-center mb-4
		@media screen and ( max-width: 576px)
			@apply col-ver
		li
			@apply text-grey-900 font-light text-clamp-18 leading-none px-2
			*
				@apply text-grey-900 font-light text-clamp-18
			@media screen and ( max-width: 576px)
				@apply py-2
			&:first-child
				@apply pl-0
			&:not(:last-child)
				@screen md
					@apply border-r border-r-grey-900