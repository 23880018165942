.home-register
	.video-wrap
		+video-ratio(410,640)
		&::after
			@apply opacity-0
		&.pause
			&::after
				@apply opacity-100
	.img-wrap
		a
			+img-ratio(410,640)
	.iframe-yt 
		+video-ratio(410,640)
	.wrap-form
		.frm-btnwrap
			@apply  col-span-2
.RadInput_Default .riEmpty,.RadInput_Empty_Default
	@apply text-clamp-16 text-grey-500 !#{important}