footer
	@apply overflow-hidden
	.Module-1347
		@apply w-full
		.ModuleContent
			@apply w-full
	.logo
		a
			height: 60px
			width: auto
			@screen lg
				height: r(140px)
		img
			width: auto
			height: 100%
			object-fit: contain
	address
		@apply not-italic text-white
		li
			@apply start-item items-start mb-3
		em
			@apply pt-1 mr-3 text-clamp-16 leading-none
			@screen xl
				@apply text-clamp-20
		p
			@apply my-auto
			span
				@apply block
	.contact
		li
			@apply gap-wrap items-center py-2 border-b border-b-primary-400
		.title
			@apply text-clamp-16 text-white leading-none
		.wrap
			@apply start-item
		a
			@apply center-item overflow-hidden rounded-full bg-white w-[40px] h-[40px] relative transition
			&:not(:last-child)
				@apply mr-4
				@screen xl
					@apply mr-6
			&::after
				content: ''
				@apply block absolute-center pointer-events-none  z-10  transition w-[32px] h-[32px] rounded-full border border-primary-900
			&:hover
				&::after
					@apply border-white
			em
				@apply leading-none text-primary-900 transition
				font-size: 16px
			img
				width: auto
				height: 16px
				object-fit: contain
				@transition