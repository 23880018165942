.elementary-1
	.item
		background: var(--color-code)
		.icon
			background: var(--color)
.elementary-2
	.headline
		@apply start-item bg-primary-900 relative -mt-6 px-2 mb-4 items-start
		&:before
			content: ''
			height: r(40px)
			width: r(40px)
			min-width: r(40px)
			background: url('../img/overlay/overlay-2.png')
			background-repeat: no-repeat
			background-size: contain
			background-position: center
			@apply mr-3 inline-block pointer-events-none z-10 mt-1
.elementary-3
	.counter
		@apply leading-none
		@screen xl
			@apply text-clamp-64
			*
				@apply text-clamp-64
	.icon
		@apply center-item
		height: 64px
		width: auto
		img
			width: auto
			height: 100%
			object-fit: contain
.elementary-4
	.title-wrap
		background: var(--color-code)
	.item
		box-shadow: 4px 4px 12px 4px #00000014
	li
		&:hover
			@apply text-primary-900 underline
.elementary-5
	.item
		&:last-child
			@media screen and ( max-width: 768px)
				@apply col-span-2
	.counter
		@apply leading-none
		color:  var(--color-code)
		@screen xl
			@apply text-clamp-64
			*
				@apply text-clamp-64
	.title-wrap
		@apply mb-3
		.headline
			@apply text-primary-900