.popup-modal
	padding: 0
	border-radius: 16px
	@apply w-full overflow-hidden
.carousel__button
	&.is-close
		top: 0 !important
		width: 50px
		height: 50px
		right: 0 !important
.register-modal
	@apply bg-primary-900 !#{important}
	padding: r(40px) r(30px) !important
	max-width: r(900px) !important
	@screen lg
		padding: r(40px) !important
	@media screen and ( min-width: 1360px)
		min-height: 620px
	@media screen and ( min-width: 1600px)
		padding: r(90px)  r(130px)  r(70px)  r(130px) !important
	.modal-wrap
		@apply m-auto w-full bg-primary-900 !#{important}
	.title
		@apply text-white uppercase font-medium text-center text-clamp-36 leading-none mb-4
		*
			@apply text-white uppercase font-medium text-center text-clamp-36 leading-none mb-4
	time
		@apply block text-clamp-24 text-white leading-none py-3 overflow-hidden rounded-1 bg-secondary-400 font-bold mx-auto text-center px-9 w-fit mb-8
		@screen xl
			@apply px-10 mb-6
		@media screen and ( min-width: 1600px)
				@apply  mb-10
		*
			@apply text-clamp-24 text-white leading-none   font-bold
	.wrap-form
		.custom-datepicker
			.RadPicker
				@apply text-white  font-light
				input[type='text']
					@apply font-light  text-clamp-16 !#{important} 
					@screen lg
						@apply font-light text-clamp-20  !#{important}
		.fa-exclamation-triangle
			@apply text-white
			*
				@apply text-white
		.form-group
			@apply relative 
			input[type='text'],textarea,select,input[type='email'],input[type='tel']
				height: 50px
				@apply  bg-transparent border-b border-b-primary-800 text-clamp-16  w-full pb-3 transition
				color: white !important
				@media screen and ( max-width: 1200px)
					height: 40px
				@screen lg
					@apply text-clamp-20 
				&:focus
					@apply  border-transparent border-b-primary-100 
				&::placeholder
					@apply text-white text-clamp-16 font-light
					@screen lg
						@apply text-clamp-20 
			select
				@apply text-clamp-16
				@screen lg
					@apply text-clamp-20 
				option
					@apply text-clamp-16 text-grey-333
					@screen lg
						@apply text-clamp-20 text-grey-333
		.custom-select
			&::after
				@apply text-white
			select
				@apply font-light
		.frm-btnwrap
			@media screen and ( max-width: 1600px)
				@apply mt-8
.recruit-modal
	max-width: r(865px)
	padding:  r(50px) r(32px)  !important
	.carousel__button
		&.is-close
			svg
				@apply text-primary-900

.home-register-modal-wrap
	.home-register-modal 
		display: block !important
		@screen lg
			width: r(900px) !important
#popup_6,#popup_7
	padding: 0 !important
	@apply overflow-hidden rounded-5

.form-apply
	iframe
		width: 100%
		height: 260px
		@screen xl
			@apply w-full
#ctl00_phMain_jobApply_upContact
	margin-bottom: 0 !important
.recruit-container
	padding: 0 !important
.apply-frm
	margin: 0 !important
	overflow: hidden
	background: #fff !important
	width: 100%
	height: 100%
	@media screen and ( max-width:  576px)
		overflow: hidden
		width: 100%
	.btn-wrap
		@apply w-full gap-wrap
		@media screen and ( max-width: 1024px)
			@apply col-ver
			align-items: flex-start
	.btn-submit
		@apply bg-primary-900 border-primary-900 text-white font-bold rounded-[12px] transition h-[48px] px-8
		min-width: 240px
	.recruitment-container
		min-width: 1110px !important
	.frm-captcha
		@apply my-6
		@screen lg
			@apply absolute right-0  pr-8
			bottom: 40px
			width: auto !important
			margin-top: 0 !important
	h4
		@apply hidden
	.frm-captcha
		@media screen and ( max-width: 1024px)
			margin-top: 20px !important
		.frm-captcha-input
			input
				@apply border h-[44px] overflow-hidden rounded-[8px]

	input[type="file"]
		left: 0
	>div
		width: 100%
	.RadUpload_Simple 
		width: 100%
		height: 100%
		input
			width: 100%
			height: 100%
	.RadUpload 
		width: 100%
		height: 100%
		pointer-events:  auto
		.ruButton
			@apply text-16 uppercase opacity-0
			font-family: 'Nunito Sans', sans-serif
		.ruInputs
			width: 100%
			position: relative
		.ruRemove
			position: absolute
			top: -20px
			right: 30px
			opacity: 1
			font-size: 12px
			color: #ff0000
			width: auto !important
			display: block
			height: 20px !important
		.ruBrowse
			@apply hidden
	#ctl00_phMain_jobApply_pnlNewComment
		position: relative
		display: flex
		flex-wrap: wrap
		>.form-group
			width: 100%
			margin-top: -10px
			position: relative
		.col-left,.col-right
			flex: 0 0 100%
			max-width: 100%
			display: flex
			flex-wrap: wrap
			>.form-group
				margin-bottom: 30px
			@media (min-width: 576px)
				margin: 0 -10px
				>.form-group
					padding: 0 10px
	.fa-exclamation-triangle
		@apply absolute font-normal 
		bottom: -22px
		left: 20px
		color: #f30000
		font-size: 12px
	.label
		display: none
		font-size: 14px
		font-weight: 400
		color: #999999
		margin-bottom: 4px
		.required
			color: red
	
	textarea
		height: 90px
		@screen xl
			height: 130px
	.RadAsyncUpload
		.ruDropZone
			display: none
		.ruFileWrap
			position: static
			display: block
			height: auto !important
			max-height: 50px
			.ruUploadProgress 
				position: absolute
				top: -25px
				left: 15px
		.ruFileInput, .rugreyse
			height: 50px
			width: 120px
			height: 50px
			text-transform: uppercase
			font-size: 15px
			display: flex
			justify-content: center
			align-items: center
			border: 1px solid #333
			background: #333
			height: 50px
			width: 250px
			&:hover
				background: white !important
				color: #333 !important
		.ruFileInput
			cursor: pointer
			border: 1px solid #333
			pointer-events: auto
			top: 0 !important
			left: 0
			padding: 0 !important
			bottom: 10px
			position: absolute
			opacity: 0
			height: 40px
			width: 100% !important
			cursor: pointer
			&:hover
				background: white !important
				color: #333 !important
	.form-group-btn
		@media screen and ( max-width: 1024px)
			@apply start-item mt-6
		@screen lg
			@apply absolute right-[20px] bottom-0
	.frm-btnwrap
		@apply start-item w-auto mt-8
		@media screen and ( max-width: 576px)
			position: static !important
	.attachfile1
		@apply block relative
		.left
			@apply absolute top-0 left-0 bottom-0 right-0 w-full h-full  cursor-pointer pointer-events-none
			z-index: 99
		label
			@apply center-item text-16  font-normal  relative pointer-events-none rounded-2 w-full bg-grey-50 text-clamp-16
			margin-top: 0
			height: 44px
			color: #7C7C7C !important
			font-weight: 400 !important
			@media screen and ( max-width: 1024px)
				@apply  px-3
				word-break: break-normal 
					
			*
				color: #7C7C7C !important
				font-weight: 400 !important
			input[type='file']
				@apply w-full cursor-pointer h-full
				
			input[type='file']::file-selector-button
				cursor: pointer
			input[type='file']::-webkit-file-upload-button 
				cursor: pointer
			::-webkit-file-upload-button 
				cursor: pointer
	.attachfile2
		display: none
	.form-group 
		position: relative
		&.disable-input
			input
				@apply pointer-events-none
				background: #F1F1F1
				border: none
	.wrap-form
		@apply w-full
		.form-group
			@apply mb-6
			input[type='text'],textarea,select,input[type='email'],input[type='tel']
				@apply border-transparent h-[45px] bg-grey-50 rounded-2 px-5 pb-0
				&::placeholder
					@apply text-grey-500
			textarea
				height: 175px
				@apply pt-3
		.frm-btnwrap
			@apply mt-4 !#{important}