.about-tour
	.iframe-video
		a	
			@screen lg
				+video-ratio(738,1312)
			@media screen and ( max-width: 1024px)
				+video-ratio(6,9)
	.img
		a
			@screen lg
				+img-ratio(738,1312)
			@media screen and ( max-width: 1024px)
				+img-ratio(6,9)