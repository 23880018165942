@layer components
	.block-title,.zone-title
		@apply text-clamp-36 font-bold leading-tight mb-7 uppercase text-primary-900
		*
			@apply text-clamp-36 font-bold leading-tight uppercase text-primary-900
		@screen xl
			@apply text-clamp-48
			*
				@apply text-clamp-48
	.section-title
		@apply text-clamp-32 font-medium text-primary-900 uppercase
		*
			@apply text-clamp-32 font-medium text-primary-900 uppercase
	.headline
		@apply text-clamp-24 font-bold leading-tight
		*
			@apply text-clamp-24 font-bold leading-tight
	.sub-title
		@apply text-clamp-24 font-bold leading-tight text-primary-900
		*
			@apply text-clamp-24 font-bold leading-tight text-primary-900
	.block-title,.zone-title,.headline,.sub-title
		@apply transition
		*
			@apply transition