.global-breadcrumb
	@apply  flex justify-start items-center 
	min-height: 50px
	.container
		@apply h-full relative flex items-center py-1
		min-height: 50px
		&::after
			content: ''
			@apply block absolute-x bottom-0 pointer-events-none  z-10  transition  h-[1px] bg-grey-200
			width: calc(100% - 30px)

	ol
		@apply p-0 list-none
		li
			@apply inline-flex relative leading-none transition
			&:not(:last-child)
				@apply mr-8
			.active
				@apply text-primary-900
				*
					@apply text-primary-900
			&:hover
				@apply text-secondary-400
				*
					@apply text-secondary-400
			&:after
				content: '\f054'
				font-family: 'Font Awesome 6 Pro' 
				@apply text-grey-666 block font-light absolute-y pointer-events-none z-10 
				right: -20px
				font-size: 16px
				height: 16px
				width: 12px
				@screen lg
					right: -24px
				@media screen and ( max-width: 1024px)
					font-size: 14px
			&:first-child
				@apply pl-0
			&:last-child
				&:after
					@apply hidden
				a
					@apply text-grey-666
			a,span
				font-size: 16px
				@apply text-grey-666 font-normal transition
.program-page
	.global-breadcrumb
		.container
			&::after
				@apply hidden
