.top-banner
	@apply relative overflow-hidden
	.wrap
		@apply relative
	.container
		@apply absolute-center text-left z-30
	.img
		+img-ratio(830,1920)
		&.bg-overlay
			@apply relative
			&::after
				content: ''
				@apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-full h-full bg-dark bg-opacity-30
	.swiper-slide
		&.swiper-slide-active
			.title
				@apply opacity-0
				animation: fade-in 1s cubic-bezier(0.39, 0.575, 0.565, 1) .7s both
	.txt
		@apply text-center
		@screen lg
			@apply w-1/2 mr-auto text-left
	.block-title
		*
			@apply text-white
		@screen xl
			@apply  text-clamp-64
			*
				@apply  text-clamp-64

		
	
		@media screen and ( max-width: 576px)
			@apply text-clamp-24