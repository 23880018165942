.admission-cambridge-1
	.box
		@apply justify-center
	.img
		a
			+img-ratio(576,416)
	.toggle-image
		@media screen and ( max-width: 1024px)
			height: 400px
		@media screen and ( max-width: 768px)
			height: 300px
		&.is-open
			.img-wrap
				@apply opacity-100
			.txt
				@apply opacity-0
		.img-wrap
			@apply opacity-0 pointer-events-none  h-full w-full
			a
				@screen lg
					@apply center-item w-full h-full
					img
						@apply w-full h-full
				@media screen and ( max-width: 1024px)
					@apply w-full
					+img-ratio(576,416)

.admission-cambridge-2
	.container-grid
		display: grid
		@media screen and ( max-width: 1024px)
			@apply grid-cols-3 gap-4
		@media screen and ( max-width: 576px)
			@apply grid-cols-2
		@media screen and ( max-width: 420px)
			@apply gap-2
		@screen lg
			@apply flex flex-wrap
		
	.txt
		background: var(--color-code)
		@apply p-5 justify-between
		@screen xl
			@apply py-8 px-10  pr-4
	.item
		@apply relative cursor-pointer
		@media screen and ( max-width: 1024px)
			&:nth-child(1),&:nth-child(6)
				@apply col-span-1
				.txt
					@apply opacity-0
		@media screen and ( max-width: 576px)
			&:nth-child(1)
				@apply col-span-2
		@screen lg
			width: 23%
			&:nth-child(1)
				width: calc( 100% - 46% )
				.txt
					@apply opacity-0
			&:nth-child(2)
			&:nth-child(3)
			&:nth-child(4)
			&:nth-child(5)
			&:nth-child(6)
				width: calc( 100% - 69% )
				.txt
					@apply opacity-0
			&:nth-child(7)
	.img
		a
			@screen lg
				display: flex
				height: r(305px)
				width: 100%
				img
					width: 100%
					height: 100%
					object-fit: cover
			@media screen and ( max-width: 1024px)
				+img-ratio(1,1)
.admission-cambridge-3
	.container-fluid
		@screen xl
			padding: 0 r(50px)
		@screen 2xl
			padding: 0 r(130px)
	.box
		background: var(--color-code)
		@apply center-item
		min-height: r(170px)
		span
			@apply block
	.desc
		h3,h4
			@apply font-bold mb-4
		ul
			@apply mb-5
			li
				@apply mb-1 leading-[1.4]
				*
					@apply leading-[1.4]
	.item
		box-shadow: 0px 0px 20px 0px #00000014
.admission-cambridge-4
	.block-title
		@screen xl
			max-width: 350px
	.number
		color: var(--color-code)
	.item
		@media screen and ( max-width: 1024px)
			@apply text-center
	.img
		a
			+img-ratio(334,594)
	.iframe-video
		a
			+video-ratio(334,594)
	.wrap-left
		@media screen and ( max-width: 1024px)
			@apply justify-center
.admission-cambridge-5
	.container-grid
		display: grid
		@media screen and ( max-width: 768px)
			@apply grid-cols-2 gap-4
		@media screen and ( max-width: 420px)
			@apply gap-2
		@screen md
			grid-auto-columns: 1fr
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr
			grid-template-rows: auto auto
			gap: 10px 10px
			grid-template-areas: "item-1 item-1 item-1 item-3 item-3 item-3 item-4 item-4 item-4 item-4 item-4 item-4" "item-2 item-2 item-2 item-3 item-3 item-3 item-5 item-5 item-5 item-6 item-6 item-6"
	.item
		@screen md
			&:nth-child(1)
				grid-area: item-1
			&:nth-child(2)
				grid-area: item-2
			&:nth-child(3)
				grid-area: item-3
				.img
					a
						@screen md
							display: flex
							height: r(700px)
			&:nth-child(4)
				grid-area: item-4
			&:nth-child(5)
				grid-area: item-5
			&:nth-child(6)
				grid-area: item-6
	.img
		a
			@screen md
				display: flex
				height: r(345px)
				width: 100%
				img
					width: 100%
					height: 100%
					object-fit: cover
			@media screen and ( max-width: 768px)
				+img-ratio(1,1)