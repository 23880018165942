.home-program
	.bg-wrap
		&::after
			content: ''
			@apply block absolute-center pointer-events-none z-10  transition w-full h-full
			background: url('../img/overlay/branding.png')
			background-repeat: no-repeat
			background-size: 100% auto
			background-position: bottom center
		@media screen and ( max-width: 1024px)
			@apply px-6
			&::after
				background-size: 100% auto
	.block-title
		@media screen and ( max-width: 440px)
			max-width: 240px
			@apply mx-auto
	.wrap-left
		@apply absolute left-0 bottom-0
	.item
		@apply h-full
		background: var(--color-code)
	.img
		@apply relative z-10
		a
			+img-ratio(250,300)
	.txt
		min-height: r(200px)
		@apply relative z-30 pt-3
		@screen lg
			padding: r(16px) r(30px)  r(70px) r(30px)
		&::after
			content: ''
			background: var(--color-code)
			@apply block absolute pointer-events-none left-0 top-[-24px] z-10  transition w-full h-[25px]
			clip-path: polygon(100% 100%, 0% 100% , 0.00% 28.35%, 2.00% 24.97%, 4.00% 21.76%, 6.00% 18.74%, 8.00% 15.94%, 10.00% 13.37%, 12.00% 11.07%, 14.00% 9.03%, 16.00% 7.29%, 18.00% 5.85%, 20.00% 4.73%, 22.00% 3.94%, 24.00% 3.47%, 26.00% 3.34%, 28.00% 3.54%, 30.00% 4.07%, 32.00% 4.94%, 34.00% 6.13%, 36.00% 7.63%, 38.00% 9.43%, 40.00% 11.52%, 42.00% 13.88%, 44.00% 16.50%, 46.00% 19.35%, 48.00% 22.41%, 50.00% 25.66%, 52.00% 29.07%, 54.00% 32.62%, 56.00% 36.27%, 58.00% 40.01%, 60.00% 43.79%, 62.00% 47.60%, 64.00% 51.41%, 66.00% 55.17%, 68.00% 58.87%, 70.00% 62.48%, 72.00% 65.96%, 74.00% 69.30%, 76.00% 72.45%, 78.00% 75.41%, 80.00% 78.15%, 82.00% 80.65%, 84.00% 82.88%, 86.00% 84.83%, 88.00% 86.48%, 90.00% 87.83%, 92.00% 88.86%, 94.00% 89.57%, 96.00% 89.94%, 98.00% 89.97%, 100.00% 89.68%)
	.swiper-slide
		padding: 0 10px
		@apply h-auto