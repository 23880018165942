.home-facility
	.img
		a
			+img-ratio(360,640)
		&::before,&::after
			content: ''
			@apply block absolute-center pointer-events-none z-10  transition w-full h-full flex justify-center col-ver opacity-0
		&::before
			@apply bg-dark bg-opacity-50
		&::after
			content: '\f03e'
			@apply text-white text-clamp-64 font-Awesome6
		&:hover
			&::before,&::after
				@apply opacity-100