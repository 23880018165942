.btn
	span,em
		transition: .4s all  ease-in-out
	&.btn-primary
		@apply border border-secondary-400 px-6 rounded-full bg-secondary-400
		height: r(44px)
		min-height: 40px
		&::after
			content: ''
			@apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-0 h-full bg-white
		&:hover
			&::after
				@apply w-full
			span
				@apply text-secondary-400
		span
			@apply text-clamp-16 uppercase font-bold relative z-20 text-white
		&.blue
			@apply bg-primary-900 border-primary-900
			&::after
				@apply bg-secondary-400
			&:hover
				@apply border-secondary-400 bg-transparent
				span
					@apply text-white
		&.red
			@apply bg-third-F15F4E border-third-F15F4E
			&:hover
				span
					@apply text-third-F15F4E
		&.yellow
			@apply bg-secondary-400 border-transparent
			&::after
				@apply w-full bg-secondary-400
			&:hover
				transform: scale(1.03)
				span
					@apply text-white
	&.btn-secondary
		@apply pr-4
		span,em
			@apply font-bold
		span
			@apply  uppercase
		em
			@apply ml-3
		&:hover
			span,em
			em
				transform: translateX(3px)
	&.btn-tertiary
		@apply pr-1
		span,em
			@apply text-white leading-none text-clamp-16 transition
		em
			@apply ml-3
		&:hover
			em
				transform: translateX(3px)
		
.prev,.next
	@apply rounded-full border border-primary-800  bg-primary-800 center-item  transition
	height: r(40px)
	width: r(40px)
	min-width: r(40px)
	@media screen and ( max-width: 1024px)
		@apply mx-3 relative
	@screen lg
		@apply absolute-y relative z-30 
	&:hover
		@apply bg-white
		em
			@apply text-primary-800
		&::after
			@apply w-full
	em
		@apply leading-none relative z-50 transition text-white text-clamp-16
	&.swiper-button-disabled
		@apply opacity-0 pointer-events-none
.swiper-nav
	@media screen and ( max-width: 1024px)
		@apply center-item pt-6 pb-1
	&.normal
		padding: 0 !important
		margin: 0 !important
		.next,.prev
			left: unset !important
			right: unset !important
			position: relative !important
			transform: none !important
		.prev
			margin-right: 20px
	&.inset
		.next
			right: 0
		.prev
			left: 0
	&.white
		.next,.prev
			@apply border-white
			em
				@apply text-white
	&.second
		.prev,.next
			@apply border-transparent rounded-full
			&::after
				@apply w-full opacity-0
			&:hover
				&::after
					@apply opacity-100
				em
					@apply text-white
	.next

		&::after
			@apply left-0
		@media screen and ( min-width: 1440.1px)
			right: -70px
		@media screen and ( min-width: 1024px) and ( max-width: 1440px)
			right: -50px
	.prev
		&::after
			@apply right-0
		@media screen and ( min-width: 1440.1px)
			left: -70px
		@media screen  and ( min-width: 1024px) and ( max-width: 1440px)
			left: -50px
.wrap-gap,.wrap-center,.wrap-left,.wrap-right,.ajax-center-wrap
	@apply flex items-center
	a
		&:not(:last-child)
			@apply mr-2
.wrap-center,.ajax-center-wrap
	@apply  justify-center
.wrap-left
	@apply justify-start
.wrap-right
	@apply justify-end
.wrap-gap
	@apply justify-between
