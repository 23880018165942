.language-wrap
	.Module
		@apply h-full
.language-list
	.active
		a
			@apply text-primary-900
	li
		&:not(:last-child)
			@apply border-r border-r-grey-500
	a
		
		&:hover
			@apply underline
