.home-admission
	&.program-summary-4
		.title-wrap
			@apply col-ver
		.block-title
			@apply mb-8
	.title-wrap
		@media screen and ( max-width: 768px)
			@apply block
			.block-title
				@apply text-center
	.tab-nav
		@media screen and ( max-width: 576px)
			+res-nav
	.img
		a
			@screen lg
				+img-ratio(460,1313)
			@media screen and ( max-width: 1024px)
				+img-ratio(9/16)
				