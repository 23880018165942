.commentpanel
	@apply hidden
	.btnShare
		min-width: auto !important
.product-rating 
	@apply pt-8 overflow-hidden
	.container
		@apply mx-auto
	.block-title
		@apply mb-2
	.commentpanel
		@apply block
	.form-wrap
		@apply border  rounded-[20px] overflow-hidden

	.product-review 
		@apply flex flex-wrap items-center
		.medium-rating 
			@apply w-full text-center
			@screen md
				@apply text-left
				width: 20%
			@media screen and ( max-width: 768px)
				border-right: 0
				@apply w-full mb-6
			.point
				font-size: clamp(32px,r(42px),42px)
				@screen lg
					font-size: 60px
				@apply font-extrabold
		.total-comment
			@apply text-grey-333 font-normal
			font-size: clamp(14px,r(16px),16px)
			
		.start-overview 
			@apply w-full
			@screen md
				width: 75%
			@screen lg
				padding: 0 50px
				width: 50%
				border-right: 1px solid #D9D9D9
			@media screen and ( max-width: 576px)
				@apply px-4
			ul 
				li 
					&:not(:last-child)
						@apply mb-4
					label
						display: flex !important
						@apply mr-2  font-semibold
						font-size: clamp(14px,r(16px),16px)
						i
							background: url('/Data/Sites/1/skins/default/js/rating/star-on.png')
							@apply block ml-2
							width: 20px
							height: 20px
							background-repeat: no-repeat
							background-size: contain
							background-position: center
					.percent
						@apply  font-medium ml-3
						font-size: clamp(14px,r(16px),16px)
						min-width: 130px
		
			.charts
			.chart
				@apply flex 

		.btn-wrap 
			@apply col-ver h-full
			padding-left: 20px
			width: 30%
			@media screen and ( max-width: 1024px)
				@apply w-full pt-6
			.btn-comment
				height: 40px
				@apply text-white font-semibold center-item  px-6 w-fit
				border-radius: 4px
				margin: 10px auto
				font-size: clamp(14px,r(16px),16px)
			p
				@apply  font-medium text-center 
				margin-bottom: r(24px)
				font-size: clamp(16px,r(20px),20px)
		.comment-form 
			@apply w-full 
			flex: 1 1 auto
			.module-title
				@apply font-bold pt-0   font-semibold  normal-case
				font-size: clamp(16px,r(20px),20px)
				margin-bottom: r(30px)
	.commentpanel .comment-form-bottom
		@apply flex py-4 px-5 border-t
		flex-direction: row
		@media screen and ( max-width: 1024px)
			@apply flex-col
	.rating-wrap
		@apply flex items-center mr-auto
		@media screen and ( max-width: 1024px)
			@apply mb-4
		span
			@apply  font-normal 
			font-size: clamp(14px,r(16px),16px)
	.comment-form .form-group
		@apply mb-0 
		@media screen and ( max-width: 1024px)
			@apply mb-4 mx-0
	.comment-form .form-group .rating-input
		@apply flex ml-2
		height: 16px
	.product-review .start-overview ul li .progress
		background: #E0E0E0
	.journalContent
		@apply w-full px-5 pt-3
		height: 120px
		@screen lg
			height: 150px
	.commentpanel 
		.btnShare
			width: 110px
			height: 38px
			@apply  text-white font-semibold  relative rounded-[4px] px-4 center-item
			font-size: clamp(14px,r(16px),16px)
			display: flex !important
			
	.commentpanel .comment-form-bottom .form-group
		min-width: auto !important
		@screen sm
			@apply mx-4
	.commentpanel .comment-form-bottom .form-group input
		min-width: 220px
		background: #F0F0F0
		height: 40px
		border-radius: 4px
		width: 220px
		border: none
		padding: 4px 24px
		@media screen and ( max-width: 1600px)
			min-width: 250px
			width: 250px
		@media screen and ( max-width: 1280px)
			min-width: auto !important
			@apply w-full
	.comment-list
		border-top: 0 !important
		.user
			@apply mt-3
			.img
				@apply rounded-full overflow-hidden
	.product-review .start-overview ul li label
		justify-content: flex-end
.comment-list 
	@media screen and ( max-width: 576px)
		margin-top: 0 !important
		padding-top: 10px !important
	.cmtinfo
		a
			@apply px-5 py-2  text-white font-semibold center-item mt-3 w-fit mr-auto rounded-[4px]
			
	.comment-item
		.item
			.user
				@apply mr-4
				.img
					width: 70px
					height: 70px
					margin-bottom: 10px
			.caption
				padding-left: 0 !important
				.mod
					display: inline-block
					line-height: 1
					vertical-align: baseline
					margin: 0 0.14285714em
					background-color: #e8e8e8
					background-image: none
					padding: 0.5833em 0.833em
					color: rgba(0, 0, 0, .6)
					text-transform: none
					font-weight: 400
					border: 0 solid transparent
					border-radius: 0.28571429rem
					-webkit-transition: background .1s ease
					transition: background .1s ease
					font-size: 0.75rem
			.name
				@apply text-14 font-bold uppercase
			.mod
				@apply pl-1
			time
				@apply hidden
	.cmteditarea,.cmtbtn
		@apply hidden
	.cmteditarea
		border: 1px solid #d6d6d6
		textarea
			@apply p-4 w-full h-full
	abbr
		@apply inline-block ml-2 text-grey-333 text-12
	.cmt-item
		.jcmt
			@apply w-full p-4 
			padding: 0 !important
			margin-left: 140px
			.cmt-area
				@apply mb-4
			
		.cmt-area
			@apply pb-3
			border-bottom: 1px solid #f1f1f1
	.jsummary
		.des
			@apply my-1
		.mod
			@apply text-12 
		.name
			@apply font-normal inline-block pr-2
			color: #223771
			font-weight: 700
			margin-bottom: 5px
			strong
				color: #223771
				font-weight: 700
				margin-bottom: 5px
		.cmlike
			@apply text-14 font-bold
			span
				@apply hidden
	.cmreply
		@apply text-14 font-bold
	.item 
		.caption 
			.name
				color: #223771
				font-weight: 700
				margin-bottom: 5px

.minidel
	@apply hidden
.cmtbtn 
	.cmtname,.cmtemail
		@apply hidden
.progress 
	@apply center-item relative
	height: 10px
	overflow: hidden
	background-color: #e9ecef
	border-radius: 4px
	width: 100%
.progress-bar 
	display: flex
	flex-direction: column
	justify-content: center
	text-align: center
	white-space: nowrap
	transition: width 0.6s ease
	@apply  absolute-y left-0 h-full