.preschool-1
	.wrap
		background: var(--color-code)
		height: max-content
		flex: 1 0 auto
	.number
		flex: 1 0 auto
		background: var(--color-code)
		width: r(80px)
		height: r(80px)
		max-height: r(80px)
		min-width: r(80px)
		@apply relative z-30 
		margin-bottom: r(-40px)
	
		@screen lg
			left: r(40px)
	.item
		@apply h-full col-hor
		@media screen and ( max-width: 1024px)
			@apply justify-center items-center

	.headline
		@apply font-medium
.preschool-2
	.swiper-main
		.img
			a
				+img-ratio(738,1312)
	.swiper-thumb
		.swiper-slide-thumb-active
			.img
				&::after
					@apply opacity-0
		.img
			@apply relative
			&::after
				content: ''
				@apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-full h-full
				background: #00000059
			a
				@media screen and ( max-width: 768px)
					+img-ratio(170,300)
				@screen md
					@apply center-item
					height: r(170px)
					width: 100%
					img
						width: 100%
						height: 100%
						object-fit: cover
.banner-offer
	.img
		@apply relative
		@media screen and ( min-width: 1200.1px)
			a
				+img-ratio(260/1320)
		@media screen and ( max-width: 1200px)
			a
				+img-ratio(9/16)
	.swiper
		@apply p-0
	.swiper-slide
		@apply p-0