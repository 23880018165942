.about-result
	.wrap
		@apply overflow-visible
	.item
		border-radius: 10px
		@apply flex flex-col items-start justify-center relative overflow-hidden
		.counter,.counter-05,.counter-1,.counter-2
			@apply text-40 font-bold block mb-3 leading-none whitespace-nowrap leading-none
		&.box-1
			@apply overflow-hidden rounded-4 bg-primary-900 p-5 pl-15
			padding-left: r(130px)
			@screen lg
				padding: r(24px) r(24px) r(24px) r(130px)
			&::after
				content: ''
				@apply block absolute pointer-events-none left-0 bottom-0 z-10  transition
				height: r(100px)
				width:  r(100px)
				background: url('../img/overlay/overlay-2.png')
				background-repeat: no-repeat
				background-size: contain
				background-position: center
			.wrap
				@apply relative z-40
				&:first-child
					@apply mb-4
			.counter
				color: var(--color-code)
		&.box-2
			margin-top: r(100px)
			@apply overflow-visible bg-primary-50
			.img
				@apply relative overflow-hidden mb-2
				margin-top: r(-75px)
				a
					@apply center-item
					height: r(240px)
					width: auto
					@media screen and ( max-width: 1280px)
						height: r(160px)
					@media screen and ( max-width: 576px)
						height: r(240px)
					img
						width: 100%
						height: 100%
						object-fit: contain
			.counter,.counter-05,.counter-1,.counter-2
				color: var(--color-code)
			.content
				@apply relative z-20
				padding: 0 r(24px) r(24px) r(24px)
				@screen xl
					padding: 0 r(30px) r(30px) r(30px)
		
		&.box-3
			@apply mb-8 bg-grey-50
			.wrap-box
				@apply p-6
				@screen xl
					@apply p-8
				.wrap
					&:not(:last-child)
						@apply mb-4
						@screen lg
							@apply mb-4
				.caption
					letter-spacing: -1px
			.counter,.counter-05,.counter-1,.counter-2
				color: var(--color-code)
			
		&.box-4
			.img-wrap
				@apply w-full
				a
					@apply w-full
					+img-ratio(320,416)
		&.box-5
			background: var(--color-code)
			padding: r(24px) 
			margin-bottom: 30px
			@screen xl
				padding: r(32px)
				@apply pb-10
			&::after
				content: ''
				@apply block absolute pointer-events-none  z-10  transition
				top: 10px
				right: 15px
				height: 75px
				width: 60px
				background: url('../img/about/medal.png')
				background-repeat: no-repeat
				background-size: cover
				background-position: center
			.counter,.counter-05,.counter-1,.counter-2
				color: var(--color-code)
			.wrap
				@apply relative z-30
		&.box-6
			background: #f3f9fc
			.wrap
				@apply w-full
			.image-wrap	
				@apply center-item w-full
				a
					@apply w-full
					+img-ratio(290,416)
			.txt
				@apply p-6
				@screen xl
					@apply p-8 pt-9 pb-10
			.counter,.counter-05,.counter-1,.counter-2
				color: var(--color-code)