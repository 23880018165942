.home-news
	.title-wrap
		@media screen and ( max-width: 768px)
			@apply block
			.block-title
				@apply text-center
	.tab-nav
		@media screen and ( max-width: 576px)
			+res-nav
.news-big
	.img
		a
			@media screen and ( max-width: 1024px)
				+img-ratio(480,640)
			@screen lg
				@apply center-item
				height: r(427px)
				width: 100%
				img
					width: 100%
					height: 100%
					object-fit: cover
	.txt
		@apply pt-5
	.headline
		@apply pt-4 pb-3
		a
			+line(2)
	&:hover
		.headline
			a
				@apply text-secondary-400
	.desc
		+line(3)
	.timeline
		@apply start-item
		time
			@apply text-grey-500 text-clamp-16 leading-none
			*
				@apply text-clamp-16 leading-none
			em
				@apply text-primary-900   mr-3
		.type
			@apply text-secondary-400 text-clamp-16 leading-none ml-4
.news-display
	.timeline
		@apply start-item
		time
			@apply text-grey-500 text-clamp-16 leading-none
			*
				@apply text-clamp-16 leading-none
			em
				@apply text-primary-900   mr-3
		.type
			@apply text-secondary-400 text-clamp-16 leading-none ml-4
.news-item-side
	@media screen and ( min-width: 400px)
		@apply start-item 
	&:hover
		.headline
			a
				@apply text-secondary-400
	.headline
		a
			+line(3)
	.timeline
		@apply start-item
		time
			@apply text-grey-500 text-clamp-16 leading-none
			*
				@apply text-clamp-16 leading-none
			em
				@apply text-primary-900   mr-3
		.type
			@apply text-secondary-400 text-clamp-16 leading-none ml-4
	.img
		flex: 1 0 auto
		a
			@apply overflow-hidden rounded center-item mr-5 rounded-2
			@media screen and ( min-width: 400px)
				width: r(200px)
				min-width: r(200px)
				max-width: r(200px)
				height: r(199px)
			@screen sm
				width: r(250px)
				min-width: r(250px)
				max-width: r(250px) 
			@screen xl
				width: r(298px)
				min-width: r(298px)
				max-width: r(298px)
			@media screen and ( max-width: 400px)
				+img-ratio(480,640)

			img
				width: 100%
				height: 100%
				object-fit: cover
	.txt
		flex: 0 1 auto
		@media screen and ( max-width: 400px)
			@apply pt-5
	.timeline
		@apply mb-4
