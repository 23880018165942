.news-display
	.news-banner
		@apply mb-8
		.img
			a
				@apply overflow-hidden rounded-4
				@screen lg
					+img-ratio(460,1313)
				@media screen and ( max-width: 1024px)
					+img-ratio(9/16)
.news-1
	.modulepager
		@apply hidden
.news-item
	.img
		a
			+img-ratio(277,416)
	&:hover
		.headline
			a
				@apply text-secondary-400
.zone-nav
	ul
		@apply center-item
	li
		@apply  pt-2
		&.active,&:hover
			a
				@apply text-secondary-400
		&:not(:last-child)
			a
				@apply border-r border-r-primary-300
	a
		@apply text-clamp-24 font-normal text-primary-900 leading-none uppercase px-6  transition
		@screen xl
			@apply px-8
	@media screen and ( max-width: 1024px)
		+res-nav