@layer components
	.desc,.zone-desc
		@apply text-clamp-18 leading-normal font-light
		*
			@apply text-clamp-18 font-light
		img
			@apply my-4 mx-auto
		strong
			@apply font-bold
			*
				@apply font-bold  
		ul,ol
			list-style-type: none
			padding-left: 10px
			li
				@apply mb-1 relative
				&::before
					content: ''
					@apply block mt-2 pointer-events-none z-10  transition w-[3px] h-[3px] bg-grey-900 rounded-full mr-3 min-w-[3px] absolute top-[4px] left-[-10px]
			ol
				@apply pl-3 pt-1
				li
					&::before
						content: ''
						@apply w-[6px] h-[1px] rounded-full mr-3 min-w-[6px]
.fullcontent
	@apply font-light leading-normal text-clamp-16 
	*
		@apply font-light leading-normal text-clamp-16 
	img,iframe
		@apply block mx-auto my-4  overflow-hidden rounded-4
	p
		@apply mb-3 text-clamp-16
		*
			@apply text-clamp-16
	figure
		@apply mb-4
		img
			@apply mb-0
		figcaption
			@apply  py-2 text-center bg-grey-100 italic
	ul,ol
		list-style-type: disc
		padding-left: 0
		li
			@apply mb-3 flex items-start
			&::before
				content: ''
				@apply block mt-2 pointer-events-none z-10  transition w-[3px] h-[3px] bg-grey-900 rounded-full mr-3 min-w-[3px]
	hr
		@apply block my-4
	h2,h3,h4,h5,h6
		@apply  mb-4 font-bold  text-primary-900
	strong
		font-weight: 700 !important
		*
			font-weight: 700 !important
	h2
		@apply text-clamp-32
	h3
		@apply text-clamp-28
	h4
		@apply text-clamp-24
	h5
		@apply text-clamp-20
	h6
		@apply text-clamp-16
	.row
		@apply mb-0 
	table
		tr
			td
				@apply text-center border border-grey-200 !#{important}
				padding: r(14px) r(24px) !important
			&:first-child
				td
					@apply font-bold text-clamp-18  bg-primary-700 text-white
		p
			@apply mb-0
	a
		@apply text-primary-800
		*
			@apply text-primary-800
		&:hover
			@apply underline