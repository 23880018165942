.recruit-detail
	.social-wrap
		em
			@apply center-item
	.info-wrap
		background: #E0EBFF
		@apply overflow-hidden rounded-4
		p
			@apply mb-2
		ul,ol
			list-style-type: disc
			counter-reset: numb 0
			li
				padding-left: 20px
				@apply mb-3 block relative
				&::before
					counter-increment: numb 1
					content: counter(numb)"."
					@apply inline-block absolute  left-0  pointer-events-none z-10  transition rounded-full  top-[2px]  w-[16px] h-[16px] min-w-[16px]  text-clamp-16 font-medium
			ul,ol
				list-style-type: disc
				li
					padding-left: 20px
					@apply mb-3 block
					&::before
						content: ''
						@apply inline-block absolute left-0  pointer-events-none z-10  transition top-[6px]  w-[6px] h-[1px]  min-w-[3px] bg-grey-900 
		em,i
			@apply mr-4 mt-1 text-clamp-18 text-primary-900
			@screen xl
				@apply mr-5
	.fullcontent
		@apply text-clamp-18
		*
			@apply text-clamp-18
		ul,ol
			li
				&::before
					@apply w-[8px] h-[8px] min-w-[8px] bg-primary-900
	.contact-info
		address
			@apply not-italic text-white
		li
			@apply text-clamp-14 mb-3
			*
				@apply text-clamp-14
			span
				@apply block text-secondary-400 mb-1
				*
					@apply text-secondary-400

			strong
				@apply text-clamp-18
				*
					@apply text-clamp-18
	.block-wrap
		@apply mb-6 border-b border-b-white border-opacity-30 pb-6
	.btn-secondary
		@apply w-full