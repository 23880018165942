header
	height: 130px
	@apply fixed top-0 left-0 right-0 w-full transition  bg-white
	z-index: 60
	box-shadow: 0px 4px 16px 0px #00000014
	@media screen and ( max-width: 1200px)
		height: 60px
body
	&.overlay-bg
		@apply overflow-hidden
	&.minimize
		@media screen and ( min-width: 1200px)
			header
				height: 70px
			.header-top
				@apply h-0 opacity-0 pt-0
			.search-overlay
				top: 70px
			.nav-brand
				a
					
					height: 50px
.nav-brand
	@apply z-20 relative
	@media screen and ( max-width: 1200px)
		@apply absolute-center
	a
		height: 50px
		width: auto
		@apply transition start-item
		@screen xl
			height: 90px
		img,svg
			width: 100%
			height: 100%
			object-fit: contain
.site-top-nav
	@media screen and ( max-width: 1200px)
		@apply hidden
		ul
			@apply flex-col items-start
		a
			@apply px-0 text-clamp-20 py-3 font-semibold
	li
		&.active
			a
				@apply text-secondary-400
.header-admissions-wrap
	@media screen and ( max-width: 1200px)
		@apply hidden
		.Module,.ModuleContent
			@apply w-full
.header-top
	@apply transition
	@screen xl
		height: 65px
	@media screen and ( max-width: 1200px)
		@apply justify-between pt-0 h-full
.nav-primary-menu
	@screen xl
		@apply h-full justify-end
	@media screen and ( max-width: 1200px)
		@apply hidden  w-full 
	.nav
		@apply end-item
		@screen xl
			@apply h-full
		@media screen and ( max-width: 1200px)
			@apply col-hor w-full h-auto
	.drop-down
		@media screen and ( max-width: 1200px)
			@apply flex flex-col
		&.is-open
			.title
				em
					&:before
						transform: rotateX(180deg)
		.nav-link-sub
			@apply start-item 
			&.active,&.current-project-ancestor
				@apply text-secondary-400
			a
				@apply normal-case font-normal py-4 px-0 
				justify-content: flex-start
				font-size: clamp(16px,r(18px),18px)
				transition: .3s all  ease-in-out
				@media screen and ( max-width: 1200px)
					@apply pt-3 pl-6 text-grey-900
				@media screen and ( min-width: 1200px)
					@apply py-2
				@media screen and ( min-width: 1200px) and ( max-width: 1400px)
					@apply  text-clamp-16
				@media screen and ( min-width: 1400px) and ( max-width: 1500px)
					@apply  text-clamp-15
				@media screen and ( min-width: 1500px) and ( max-width: 1700px)
					@apply  text-clamp-17
				@media screen and ( min-width: 1700px)
					@apply   text-clamp-18
				&:hover
					@apply text-secondary-400
					transform: translateX(5px)
		.title
			@apply relative
			@media screen and ( max-width: 1200px)
				@apply gap-item w-full 
			@screen xl
				@apply center-item p-0 h-full
			a
				@apply relative  transition py-5 h-full
				&::after
					content: ''
					height: 2px
					@apply block absolute-x pointer-events-none bottom-0 z-10  transition  w-0 bg-secondary-400
				@media screen and ( max-width: 1200px)
					@apply py-3 px-0
					&::after
						@apply hidden
			em
				&:before
					@apply transition
				@screen xl
					@apply hidden 
				@media screen and ( max-width: 1200px)
					width: 40px
					height: 30px
					font-size: 20px
					@apply  absolute right-0 top-50 translate-y-50 text-grey-300 end-item
		@screen xl
			&:hover
				.nav-sub
					@apply opacity-100 pointer-events-auto transform-none
		.nav-sub
			@media screen and ( max-width: 1200px)
				@apply hidden  py-2 w-full
			@screen xl
				@apply absolute top-full left-0 w-max z-20 opacity-0 pointer-events-none border-none py-2 px-6 transition bg-white shadow-lg
				transform: translateY(25%)
	.nav-link
		@apply relative z-10 
		@media screen and ( max-width: 1200px)
			@apply  w-full start-item 
		@screen xl
			margin: 0 r(8px)
			@apply h-full  center-item
			&:first-child
				margin-left: 0
			&:last-child
				margin-right: 0
		@media screen and ( min-width: 1600px)
			margin: 0 r(11px)
			
				
		&.active,&:hover,&.current-project-ancestor
			.title
				a
					@apply text-secondary-400
					&::after
						@apply w-full
			>a
				@apply text-secondary-400
				&::after
					@apply w-full
		>a
			&::after
				content: ''
				@apply block absolute-x pointer-events-none bottom-0 z-10  transition  w-0 bg-secondary-400
				height: 2px
				@media screen and ( max-width: 1200px)
					@apply hidden
			@media screen and ( max-width: 1200px)
				@apply py-4
			@screen xl
				@apply h-full  center-item  py-5
		a
			@apply center-item leading-none  relative  transition text-clamp-20
			@media screen and ( max-width: 1200px)
				@apply font-semibold normal-case
			@media screen and ( min-width: 1200px) and ( max-width: 1400px)
				@apply  text-clamp-16
			@media screen and ( min-width: 1400px) and ( max-width: 1500px)
				@apply  text-clamp-15
			@media screen and ( min-width: 1500px) and ( max-width: 1700px)
				@apply  text-clamp-17
			@media screen and ( min-width: 1700px)
				@apply  h-full  text-clamp-18
.site-menu-toggle
	@apply  center-item items-center h-full mt-1
	@screen xl
		@apply hidden
.mobile-nav-wrap
	@apply fixed top-0 left-0 w-full h-full  h-screen px-6 py-8 bg-white shadow-lg
	z-index: 9999
	transform: translateX(-100%)
	transition: transform .8s cubic-bezier(.165,.84,.44,1)
	@screen md
		width: 320px
	@media screen and ( min-width:1200px )
		@apply hidden 
	&.is-open
		transform: none
	.close-menu
		@apply start-item 
		em,span
			@apply leading-none text-grey-300
		em
			@apply text-clamp-32 font-semibold
		span
			@apply text-clamp-20 ml-3 font-bold
	.mobile-top-nav
		@apply gap-wrap mb-5
