main
	padding-top: 130px
	@media screen and ( max-width: 1024px)
		padding-top: 60px
.swiper-slide
	height: auto 
.five-swiper,.four-swiper,.three-swiper,.two-swiper,.single-swiper
	@apply relative z-50
	.swiper
		@apply -m-3 p-3 -mx-8 px-2
		@media screen and ( max-width: 1200px)
			@apply -mx-6
		@media screen and ( max-width: 1024px)
			@apply -mx-5
	.swiper-slide
		padding: 0 16px
		@media screen and ( max-width: 1200px)
			padding: 0 10px
	
.five-swiper
	.swiper-slide
		@apply w-1/5
		@media screen and ( max-width: 1200px)
			@apply w-1/4
		@media screen and ( max-width: 1024px)
			@apply w-1/3
		@media screen and ( max-width: 768px)
			@apply w-1/2
		@media screen and ( max-width: 460px)
			@apply w-full
.four-swiper
	.swiper-slide
		@apply w-1/4
		@media screen and ( max-width: 1024px)
			@apply w-1/3
		@media screen and ( max-width: 768px)
			@apply w-1/2
		@media screen and ( max-width: 460px)
			@apply w-full
.double-swiper
	.swiper-slide
		@apply w-1/2
		@media screen and ( max-width: 460px)
			@apply w-full
.three-swiper
	.swiper-slide
		@apply w-full
		@media screen and ( min-width: 460px)
			@screen xl
				@apply w-1/2
		@screen md
			width: 33.33%
.two-swiper
	.swiper-slide
		width: 50%
		@media screen and ( max-width: 460px)
			@apply w-full
.single-swiper
	@apply m-0
	.swiper
		@apply m-0 p-0
	.swiper-slide
		width: 100%
		@apply p-0
.main-menu-toggle
	@apply hidden
	@media screen and ( max-width:  1024px)
		@apply flex items-center pl-8
	@media screen and ( max-width:  768px)
		@apply pl-4
.frm-thanks
	@apply my-16 p-8 text-center text-clamp-24 text-white font-bold
	margin: 0 auto
	width: 500px
	*
		@apply text-clamp-24 text-white font-bold
	h3,h4,h5
		@apply text-red font-bold text-red text-16 mb-3
	p,span
		@apply text-clamp-24 text-white font-bold
		*
			@apply text-clamp-24 text-white font-bold
	@media screen and ( max-width: 1024px)
		width: 320px
.tab-item
	@apply hidden
	&.active
		@apply block
	.prev,.next
		transition: none !important
.edit-link
	width: 30px
	height: 30px
	pointer-events: auto
	.fa-pencil
		width: 18px
		height: 18px
		background-size: contain
		background-repeat: no-repeat
		background-position: center
		background-image: url("data:image/svg+xml;charset=utf-8,<svg  viewBox='0 0 18 18'  xmlns='http://www.w3.org/2000/svg'><path d='M6.35473 15.7485L2.27202 11.6658L12.1468 1.79106L16.2295 5.87378L6.35473 15.7485ZM1.89039 12.385L5.63556 16.1302L0.0205078 18L1.89039 12.385ZM17.4852 4.62344L16.7771 5.33159L12.6889 1.24347L13.3971 0.535326C14.1104 -0.178442 15.2672 -0.178442 15.9805 0.535326L17.4852 2.04001C18.1934 2.75572 18.1934 3.90795 17.4852 4.62344Z' fill='#094594'/></svg>")
.custom-select
	@apply relative w-full
	&::after
		content: '\f0d7'
		font-family: 'Font Awesome 6 Pro'
		@apply block absolute-y pointer-events-none right-[20px] z-10  transition leading-none font-bold text-primary-900
		height: 16px
		width: 8px
		font-size: 14px
	select
		-webkit-appearance: none
		-moz-appearance: none
		@apply  w-full
		@media screen and ( max-width: 1024px)
			height: 40px
		option
			@apply py-1 px-2
.scrollbar-wrap
	height: 300px
	padding-right: 15px
	overflow-y: auto
	scrollbar-width: thin
	scrollbar-color: #F15F4E #DCDCDC
	&::-webkit-scrollbar
		background: #DCDCDC
		width: 3px
		border-radius: 5px
	&::-webkit-scrollbar-track
		-webkit-box-shadow: inset 0 0 6px #DCDCDC
		background-color: #DCDCDC
		border-radius: 5px
	&::-webkit-scrollbar-thumb
		background-color: #F15F4E
		border-radius: 5px
	@media screen and ( max-width:1024px)
		height: auto
.qradio
	input
		background-repeat: no-repeat
		background-position: center
		appearance: none
		@apply block
		width: 20px
		height: 20px
		background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='black' width='20px' height='20px'><path d='M0 0h24v24H0z' fill='none'/><path d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'/></svg>")
		&:checked
			background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23ff0000' width='20px' height='20px'><path d='M0 0h24v24H0z' fill='none'/><path d='M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'/></svg>")
.table-wrap
	@media screen and ( max-width:1024px)
		overflow-x: auto
	table
		@apply w-full
		@media screen and ( max-width:1024px)
			white-space: nowrap
.drop-list
	em,span
		@apply text-white leading-none
	.fa-globe
		@apply hidden
		@screen sm
			@apply inline-block
	em
		@apply text-clamp-18
		@screen xl
			@apply text-clamp-16 
	span
		@apply text-clamp-16 font-normal mr-3
		@screen sm
			@apply mx-3
	&:hover
		.fa-chevron-down
			transform: rotateX(180deg)
		.dropdown
			@apply filter-none pointer-events-auto opacity-100
	.drop-title
		@apply px-5 py-2 overflow-hidden rounded-5 leading-none center-item h-full border-white  border border-opacity-50
		span
			@media screen  and ( max-width: 768px)
				@apply hidden
		.fa-chevron-down 
			@media screen  and ( max-width: 768px)
				@apply ml-3
	.dropdown
		filter: blur(1px)
		@apply opacity-0 pointer-events-none transition bg-white px-8 py-3  col-ver absolute-x top-full  w-max rounded overflow-hidden z-40
		li
			&:not(:last-child)
				@apply mb-2
			&.active
				@apply font-bold
		a
			@apply text-grey-333 font-semibold start-item text-left text-clamp-18
			
			@screen lg
				@apply text-clamp-14
			&:hover
				@apply underline
.sidenav-group
	@apply fixed  right-0 z-50 top-[160px]  transition 
	@media screen and ( max-width: 576px)
		@apply right-[5px] top-[200px]
	li
		@apply mb-3
		@screen xl
			@apply mb-4
	em
		@apply text-white text-clamp-24 transition
		@media screen and ( max-width: 576px)
			@apply text-clamp-20
	img
		max-width: r(45px)
		@media screen and ( max-width: 576px)
			max-width: r(32px)

	&.show
		.back-to-top
			@apply opacity-100 pointer-events-auto
	a
		@apply center-item rounded cursor-pointer transition shadow-lg
		width: r(64px)
		height: r(64px)
		@media screen and ( max-width: 576px)
			width: 36px
			height: 36px
		&:hover
			@apply no-underline
	.contact
		@apply border border-primary-700 bg-primary-600
		&:hover
			@apply bg-white
			em
				@apply text-primary-600
.back-to-top
	@apply  bg-grey-500  border border-white border-opacity-50 opacity-0 pointer-events-none
	&:hover
		@apply bg-primary-600
.section-tab-nav
	li
		&:last-child
			a
				@apply pr-0
		&:not(:last-child)
			@apply border-r border-r-primary-300
		&.active
			a
				@apply text-secondary-400

.play-icon
	@apply relative
	&::after
		content: ''
		@apply block  pointer-events-none  z-50  transition w-full h-full absolute-center text-primary-900 rounded-full overflow-hidden center-item
		width: r(80px)
		height: r(80px)
		min-width: r(80px)
		background: url('../img/icon/play.png')
		background-repeat: no-repeat
		background-size: cover
		background-position: center
		@media screen and ( max-width: 1024px)
			width: r(64px)
			height: r(64px)
			min-width: r(64px)
.recruit-page
	.modulepager
		@apply hidden