.program-1
	.img
		background: url('../img/bg/about-1.jpg')
		background-repeat: no-repeat
		background-size: cover
		background-position: center
		@apply pt-8 pl-10 relative
		&::after
			content: ''
			@apply block absolute pointer-events-none right-0 top-[30px] z-10  transition w-full h-full
			background: url('../img/bg/about-2.png')
			background-repeat: no-repeat
			background-size: contain
			background-position: center
			right: r(-100px)
			width: r(150px)
			height: r(200px)
			@media screen and ( max-width: 768px)
				right: 0
		@screen xl
			@apply pt-12
		@screen xl
			@apply pt-20
		a
			@apply flex items-end 
			@screen lg
				@apply -mr-15
	.row
		@apply justify-between m-0 p-0
		@screen md
			border-bottom: 20px solid #FAAF3C
		@screen lg
			border-bottom: 36px solid #FAAF3C 
		.col
			@apply m-0 p-0
	.desc
		p
			@apply mb-2
		ul
			@apply text-left
			li
				@apply text-primary-00B9AB 
				&:not(:last-child)
					@apply mb-2
					@screen xl
						@apply mb-3
				*
					@apply text-primary-00B9AB
				&:before
					@apply bg-primary-00B9AB
	.txt
		@apply col-ver justify-center
		@media screen and ( max-width: 1024px)
			@apply pl-10
		@media screen and ( max-width: 768px)
			@apply pt-8 pl-0
	.scrollbar-wrap
		@screen lg
			@apply h-auto
		@media screen and ( max-width: 1024px)
			height: r(400px)
	.block-title
		@apply text-clamp-40 font-bold
		*
			@apply text-clamp-40 font-bold
		@screen xl
			@apply  text-clamp-48
			*
				@apply text-clamp-48
.program-2
	.row
		@apply justify-center
	.icon
		height: r(90px)
		width: auto
		img
			width: auto
			height: 100%
			object-fit: contain
.program-3
	.container-grid
		@apply overflow-hidden
		display: grid
		@media screen and ( max-width: 1024px)
			@apply grid-cols-3 gap-4
		@media screen and ( max-width: 576px)
			@apply grid-cols-2
		@media screen and ( max-width: 420px)
			@apply gap-2
		@screen lg
			@apply flex flex-wrap
	.txt
		width: 101%
		height: 101%
		&::after
			content: ''
			@apply block absolute pointer-events-none left-0 bottom-0 z-10  transition
			width: 101%
			height: 101%
			background: var(--color-code)
		@apply p-6 justify-between
		@screen xl
			@apply py-10 px-7  pr-4
	.item
		@apply relative cursor-pointer overflow-hidden
		&:nth-child(1),&:nth-child(10)
			.txt
				@apply opacity-0
		&:nth-child(6)
			.txt
				@apply flex-col-reverse
				&::after
					@apply opacity-100
		&:nth-child(7)
			.txt
				&::after
					@apply opacity-100
		@media screen and ( max-width: 1024px)
			&:nth-child(1),&:nth-child(6)
				@apply col-span-1
		@screen lg
			width: 23%
			&:nth-child(1),&:nth-child(10)
				width: calc( 100% - 46% )
			&:nth-child(4),&:nth-child(5),&:nth-child(6),&:nth-child(7)
				@apply w-1/4
			
		
				
	.img
		a
			@screen lg
				display: flex
				height: r(305px)
				width: 100%
				img
					width: 100%
					height: 100%
					object-fit: cover
			@media screen and ( max-width: 1024px)
				+img-ratio(1,1)
.program-4
	.block-title
		@apply text-secondary-400  
		*
			@apply text-secondary-400
		@screen xl
			@apply text-right
	.img
		@apply bg-white relative z-50
		&::after
			content: ''
			@apply block absolute pointer-events-none left-0 bottom-0   transition w-full h-full opacity-80
			z-index: 60
			background: linear-gradient(90deg, rgba(0, 0, 0, 0.00) 12.4%, #000 100%)
		a
			@apply relative z-50
			@media screen and ( max-width: 1024px)
				+img-ratio(9,16)
			@screen lg
				+img-ratio(656,1312)
	.txt
		@apply ml-auto w-full pt-10 text-center
		@screen lg
			@apply pr-15 w-7/12 
	.desc
		@screen lg
			@apply text-white -mr-[10px]
			*
				@apply text-white
	.item
		.container
			@apply p-0
	ul
		@apply text-right
		@media screen and ( max-width: 576px)
			@apply pl-0 
		li
			@apply pr-[25px]
			@media screen and ( max-width: 576px)
				@apply text-left !#{important}
				*
					@apply text-left !#{important}
			&:not(:last-child)
				@apply mb-4
			&:before
				@apply w-[9px] h-[9px] min-w-[9px] bg-secondary-400
				left: unset 
				right: 0
