.comment-list
	padding-bottom: r(110px)
	.two-swiper
		@apply -mx-5
		.swiper
			@apply -m-6 -mx-1 px-1 py-8
			@media screen and ( max-width: 1200px)
				@apply -mx-6
			@media screen and ( max-width: 1024px)
				@apply -mx-5
			.swiper-slide
				@media screen and ( max-width: 576px)
					@apply w-full
	&.admission-comment
		padding-bottom: r(60px)
	.swiper-pagination
		@apply center-item  pt-8
		bottom: -30px !important
		
	.swiper-pagination-bullet
		@apply  rounded-full transition opacity-100 center-item bg-transparent relative  center-item overflow-hidden
		background: #DCDCDC
		height: 14px !important
		width: 14px !important
		transform: scale(1) !important
		&.swiper-pagination-bullet-active
			@apply opacity-100 border-white 
			background: #bdbdbd
	.swiper-slide
		@apply h-auto
.comment-item
	@apply relative rounded-4 h-full
	box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08)
	padding: r(30px)
	@media screen and ( max-width: 1200px)
		@apply col-ver
	@screen xl
		padding: r(60px) r(25px) r(50px) r(40px)
	svg
		@apply absolute top-[-20px]
		max-height: r(80px)
		@media screen and ( max-width: 1600px)
			max-height: r(70px)
			@apply top-[-15px]
		@media screen and ( max-width: 1200px)
			max-height: r(50px)
		
		path
			fill: var(--color-code)
	.icon
		height: r(150px)
		width: r(150px)
		min-width: r(150px)
		@apply mb-4
		@media screen and ( max-width: 1200px)
			@apply mx-auto my-4
	.scrollbar-wrap
		height: r(260px)
		@media screen and ( max-width: 1200px)
			height: r(200px)
	.profile
		@media screen and ( max-width: 1200px)
			@apply order-2 text-center
		@screen lg
			min-width: r(200px) 
	.txt 
		@media screen and ( max-width: 1200px)
			@apply order-1 pt-8
		@screen xl
			@apply pl-12 
	.title
		word-break: break-words
		@apply mb-3 text-primary-900