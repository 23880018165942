.program-detail-1
	.dash-line
		@apply my-8 border-grey-200
	.img
		+img-ratio(360,560)
	.row
		@apply justify-between
.program-detail-2
	@apply pb-15
	.fullcontent
		h3,h4,h5
			@apply text-primary-900 font-bold text-clamp-24 uppercase
			*
				@apply text-primary-900 font-bold text-clamp-24
		h4,h5
			@apply text-clamp-18
			*
				@apply text-clamp-18
		h2
			@apply text-white font-bold uppercase text-clamp-24 py-3 px-8 rounded-full bg-secondary-400 leading-none w-fit my-4
			*
				@apply text-white font-bold uppercase text-clamp-24