.admission-1
	@apply overflow-hidden
	.img
		a
			@screen lg
				+img-ratio(738,1312)
			@media screen and ( max-width: 1024px)
				+img-ratio(6,9)
	.title
		@screen md
			max-width: 200px
			@apply ml-auto 
	.iframe-video
		a	
			@screen lg
				+video-ratio(738,1312)
			@media screen and ( max-width: 1024px)
				+video-ratio(6,9)
.admission-2
	@apply overflow-hidden
	&::after
		content: ''
		@apply block absolute pointer-events-none right-0 bottom-0 z-10  transition w-full h-full
		background: url('../img/overlay/overlay-1.png')
		background-repeat: no-repeat
		background-size: contain
		background-position: center
		width: r(390px)
		height: r(390px)
		@media screen and ( max-width: 1200px)
			width: r(300px)
			height: r(300px)
	.item
		@media screen and ( max-width: 576px)
			@apply flex-col
	.col-span-2
		&:nth-child(1)
			order: 1
		&:nth-child(2)
			order: 3
		&:nth-child(3)
			order: 5
		&:nth-child(4)
			order: 2
		&:nth-child(5)
			order: 4
		&:nth-child(6)
			order: 6
	.number
		background: var(--color-code)
		width: r(80px)
		height: r(80px)
		min-width: r(80px)
		@media screen and ( max-width: 576px)
			width: r(72px)
			height: r(72px)
			min-width: r(72px)
			@apply -mb-4
	.txt
		border: 2px solid  var(--color-code)
		min-height: r(84px)
		@apply flex items-center p-4 pt-8
		@screen sm
			padding: r(12px) r(18px) r(12px) r(50px)
			margin-left: r(-40px) 
		@screen lg
			padding: r(12px) r(32px) r(12px) r(64px)
	&.expand
		.txt
			@apply py-4 items-start
			@media screen and ( max-width: 576px)
				@apply py-8
.admission-3
	@apply overflow-hidden
	.row
		@apply justify-between
	.number
		width: 30px
		height: 30px
		min-width: 30px
		top: -8px
		left: -8px
	.txt
		@screen lg
			@apply p-3
		@media screen and ( min-width: 1600px) 
			@apply p-4 
		@apply flex flex-col justify-between
	.wrap-left
		@screen lg
			@apply pb-10
	.img
		a
			+img-ratio(480,640)
	.tab-nav
		@apply relative z-40 pb-6
		@screen lg
			@apply absolute-x pb-0 top-8
		a
			@media screen and ( min-width: 1024px) 
				max-width: r(270px)
			@media screen and ( min-width: 1200px) 
				max-width: r(260px)
			@media screen and ( min-width: 1400px) 
				max-width: r(220px)
			@media screen and ( min-width: 1600px) 
				max-width: r(180px)
		li
			@apply mb-5
			&.active
				.number,.box
					@apply bg-secondary-400

.admission-4
	@apply overflow-hidden
	.img
		&::after
			content: ''
			@apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-full h-full
			opacity: .85
			background: var(--color-code)
		a
			+img-ratio(1,1)
	.txt-wrap
		@media screen and ( max-width: 768px)
			@apply justify-center
	.name
		background: var(--color-code)
		@media screen and ( max-width: 768px)
			@apply absolute-x bottom-[30px]
		@media screen and ( max-width: 400px)
			@apply bottom-[15px]
	.character
		@apply  text-white opacity-50 uppercase  font-black leading-none py-3 
		text-shadow: 0px 4px 4px 0px #00000040
		@media screen and ( max-width: 768px)
			font-size: r(190px)
			@apply leading-[.7] py-4
		@screen md
			@apply leading-[.7]
			font-size: r(70px)
		@screen md
			font-size: r(140px)
		@screen lg 
			@apply pb-4
			font-size: r(190px)
	.bg-wrap
		@apply bg-grey-50 rounded-4 p-5
		@screen sm
			@apply  p-[30px] pt-0
	.item
		margin-top: -30px
	.txt
		@apply pt-5
		@screen md
			@apply px-8 py-5 pb-0
	.block-wrap
		.bg-wrap
			&:not(:last-child)
				@apply mb-15
	.scrollbar-wrap
		@screen md
			max-height: r(140px)
		@screen lg
			max-height: r(200px)
.admission-5
	.item
		@apply flex flex-col
	.txt
		background: var(--color-code)
		@apply h-full
		flex: 0 1 auto
	.img
		@apply h-fit
		flex: 1 0 auto
		a
			+img-ratio(1,1)
.admission-news
	.container-grid
		@apply overflow-hidden rounded-4 
		display: grid
		grid-template-columns: 1fr 1fr
		grid-template-rows: auto auto
		gap: 0px 0px
		grid-template-areas: "item-1 item-2" "item-1 item-3"
		@media screen and ( max-width: 768px)
			grid-template-areas: "item-1 item-1" "item-2 item-3"
	.txt
		&::before
			content: ''
			@apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-full h-full
			background: linear-gradient(180deg, rgba(43, 51, 122, 0) 50%, #2B337A 100%)
		&::after
			content: ''
			@apply block absolute pointer-events-none bottom-0 right-0 z-10  transition
			width: r(86px)
			height: r(86px)
			background: url('../img/overlay/overlay.png')
			background-repeat: no-repeat
			background-size: contain
			background-position: center
			@media screen and ( max-width: 1024px)
				width: r(64px)
				height: r(64px)
			@media screen and ( max-width: 576px)
				width: r(40px)
				height: r(40px)
	.item
		&:nth-child(1)
			grid-area: item-1
		&:nth-child(2)
			grid-area: item-2
			@screen sm
				a
					height: r(328px)
		&:nth-child(3)
			grid-area: item-3
			@screen sm
				a
					height: r(328px)
	.img
		@apply h-full w-full
		@media screen and ( max-width: 576px )
			a
				+img-ratio(1,1)
		@screen sm
			a
				@apply center-item
				height: r(656px)
				width: 100%
				img
					width: 100%
					height: 100%
					object-fit: cover 
.admission-step
	.bg-wrap
		background: url('../img/overlay/map.png')
		background-repeat: no-repeat
		background-size: 100% 100%
		background-position: center
		@media screen and ( max-width: 1024px)
			background-size: contain
	.padding-wrap
		@screen lg
			padding: 120px 0
	.block-wrap
		@apply relative
		padding: 0 10px
		&::after
			content: ''
			@apply block absolute-center pointer-events-none  z-10  transition w-full h-full
			background: url('../img/overlay/curve.png')
			background-repeat: no-repeat
			background-size: 100% 100%
			background-position: center
			@media screen and ( max-width: 1024px)
				@apply hidden
	.item
		@apply  relative
		&:not(:first-child)
			@apply pt-8
		@screen lg
			@apply py-8 
		&::after
			content: ''
			@apply block absolute-x pointer-events-none left-0 bottom-0 z-20  transition  rounded-full overflow-hidden
			width: 16px
			height: 16px
			background: var(--color-code) 
			@media screen and ( max-width: 1024px)
				@apply hidden
		&:nth-child(even)
			&::after
				@apply bottom-[-8px]
			.txt
				@apply bottom-full
		&:nth-child(odd)
			&::after
				@apply top-[-8px]
			.txt
				@apply top-full
	.txt
		@apply pt-6
		@screen lg
			@apply w-full absolute-x pt-0
	.circle
		@apply rounded-full overflow-hidden center-item
		border: 1px solid var(--color-code) 
		width: r(190px)
		height: r(190px)
		@media screen and ( max-width: 576px)
			width: r(140px)
			height: r(140px)

	.icon
		@apply rounded-full overflow-hidden center-item p-5
		background: var(--color-code) 
		width: r(160px)
		height: r(160px)
		@media screen and ( max-width: 576px)
			@apply p-8
			width: r(120px)
			height: r(120px)
	.desc
		+line(3)
	.headline
		color: var(--color-code) 
.admission-timline
	.item
		&:first-child
			@apply pt-0
	.img-wrap
		a
			@screen md
				@apply center-item
				height: r(480px)
				width: 100%
				img
					width: 100%
					height: 100%
					object-fit: cover 
			@media screen and ( max-width: 768px)
				+img-ratio(480,640)
	.scrollbar-wrap
		height: r(480px)
		@apply pl-[20px]
	time
		&::after
			content: ''
			@apply block absolute-y pointer-events-none z-10  transition w-[10px] h-[10px] rounded-full bg-third-F15F4E
			left: -20px
.admission-explore
	.section-tab-nav
		@media screen and ( max-width: 380px)
			+res-nav
	.iframe-video
		a	
			@screen lg
				+video-ratio(750,1310)
			@media screen and ( max-width: 1024px)
				+video-ratio(6,9)
.admission-guide
	.item
		box-shadow: 4px 4px 12px 4px #00000014
	.title-wrap
		background: var(--color-code)
	ol,ul
		list-style-type: disc
		padding-left: 20px
	li
		&:not(:last-child)
			@apply mb-1
	a
		@apply text-grey-900 text-clamp-18 font-light
		*
			@apply text-grey-900 text-clamp-18 font-light
		&:hover
			@apply text-primary-900 underline
			*
				@apply text-primary-900 underline
.admission-register-form
	.bg-wrap
		@media screen and ( max-width: 1024px)
			@apply pb-10
	.title-wrap
		@apply pt-10
		&::after
			content: ''
			@apply block absolute pointer-events-none right-0 top-0 z-10  transition 
			background: url('../img/overlay/overlay-2.png')
			background-repeat: no-repeat
			background-size: contain
			background-position: center
			width: r(94px)
			height: r(94px)
		@screen lg
			@apply w-1/2 ml-auto 
			margin-bottom: r(-70px)
		@screen xl
			max-width: r(430px)
		@screen 2xl
			max-width: r(350px)
		.block-title
			@apply mb-0 
	.form-wrap
		@apply my-auto
	.wrap-form
		.form-group
			@apply relative 
			input[type='text'],textarea,select,input[type='email'],input[type='tel']
				height: 50px
				@apply  bg-transparent border-b border-b-grey-200 text-clamp-16  w-full pb-3 transition text-grey-500
				@media screen and ( max-width: 1200px)
					height: 40px
				@screen lg
					@apply text-clamp-20 
				&:focus
					@apply border-primary-100
				&::placeholder
					@apply text-grey-500 text-clamp-16
					@screen lg
						@apply text-clamp-20 
			select
				@apply text-clamp-16
				@screen lg
					@apply text-clamp-20 
				option
					@apply text-clamp-16
					@screen lg
						@apply text-clamp-20 
		.custom-datepicker
			.RadPicker
				@apply  font-normal
				input[type='text']
					@apply font-normal  text-clamp-16 !#{important} 
					@screen lg
						@apply font-normal text-clamp-20  !#{important}
		.frm-btnwrap
			@screen xl
				@apply mt-15