//Type 1 first child icon
.modulepager,.post-nav
	@apply flex items-center justify-center pt-6 pb-0
	@media screen and ( max-width:1024px)
		padding-bottom: 30px
	ul
		padding: 0
		margin: 0
		position: relative
		li
			display: inline-block
			padding: 0 10px
			&.active
				a,span
					@apply bg-secondary-400
			&:hover
				a,span
					@apply bg-secondary-400
			
			span,a
				transition: .3s all  ease-in-out
				width: 40px
				height: 40px
				text-decoration: none
				@apply text-white text-clamp-18 rounded-full font-bold center-item bg-primary-900
		.next
			a
				width: max-content
				@apply whitespace-nowrap
					
