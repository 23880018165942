.home-partner
	.bg-wrap
		@apply px-8 py-12
		@screen xl
			padding: r(70px) r(100px)
	.img
		a
			@apply center-item
			height: r(80px)
			width: auto
			img
				width: auto
				height: 100%
				object-fit: contain 
	.four-swiper
		.swiper-slide
			@apply w-1/4
			@media screen and ( max-width: 1024px)
				@apply w-1/3
			@media screen and ( max-width: 768px)
				@apply w-1/2